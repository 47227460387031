<template lang="pug">
  .ManageDate
    .VerificationDataDate
      p.text-center.text-primary.font-semibold.mt-8.mb-2.mx-3
        | Validación de datos de cita
      article.mx-3
        p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
          | Verifica la información antes de programar tu cita
      .mx-3
        card-date(
          :date="manageDate"
          :acordionNo="true"
          :acordionNoShow="true"
          :noEdit="true"
        ).max-w-sm.mx-auto.list-none
        div
          .flex.items-center
            .inline-block.mr-3
              div.mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.opacity-50
                span(class="material-icons-outlined").text-primary
                  | event_available
            article.m-0.w-full.flex.items-center
              p.mx-1: small.text-xs.text-primary: strong
                | {{ manageDate.label }}
              p.ml-2: small {{ manageDate.hora }}
          hr.opacity-20.mt-2.mb-1
          .flex.items-center
            .inline-block.mr-3
              div.mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.opacity-50
                span(class="material-icons-outlined").text-primary
                  img(src="@/assets/images/pill_icon.svg")
            article.m-0.w-full
              p.mx-1: small.text-base.text-info_color
                | Aqui podemos entregar las siguientes ordenes
                .flex.mt-3.p-1.dkbackgroundlinks
                  //- p.small-p: small.text-xs.mx-1.text-secondary 125 -
                  //-   span.small-p: small.text-xs.mx-1.text-secondary
                  //-     |125689
                  p.small-p: small.text-base.mx-1.text-secondary(v-if="manageDate.reason" v-for="(numb, i) in manageDate.reason.formulas.split(';')" :key="i") {{numb}}
      footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full
        button-component(
          text="Modificar cita"
          themeSelected="primaryOther"
          :onClick="handleUpdateDate")
        br
        button-component(
          text="Cancelar cita"
          themeSelected="danger"
          :onClick="handleCancelDate")
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import loadingMessage from "../utils/loadingMessage";

export default {
  name: "ManageDate",
  components: {
    CardDate: () => import("../components/CardDate.vue"),
    ButtonComponent: () => import("../components/general/Button.vue"),
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState({
      manageDate: (state) => state.drugstore.manageDate,
      env: (state) => state.environment.environment.env,
      closeModal: (state) => state.root.closeModal
    }),
  },
  methods: {
    ...mapActions("root", [
      "handleShowModal",
      "changeTrip",
    ]),
    ...mapActions(["fetchSchedule"]),
    ...mapMutations("drugstore", {
      setStateDrugstore: "setState",
    }),
    handleCancelDate() {
      this.handleShowModal(loadingMessage({
        title: "Seguro quieres cancelar tu cita",
        description: "Después de haber cancelado tu cita puedes solicitar una nueva.",
        state: "danger",
        otherButtonCancel: true,
      }));
    },
    async handleUpdateDate() {
      
      this.handleShowModal(loadingMessage({
        title: "Consultando agendamiento...",
        state: "loading",
      }));
      
      await this.fetchSchedule({
        url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
        headers: {
          'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
        },
        queryData: '?branchId=' + this.manageDate.branch
      });
      
      this.closeModal();

      this.setStateDrugstore({ key: "drugstoreState", value: {...this.manageDate, branch_id: this.manageDate.branch, removeEditDrugstore: true}, });
      this.changeTrip("VerificationDataDate");
    }
  },
}
</script>

<style scoped>
.dkbackgroundlinks {
  background-color: rgba(97, 174, 247, 0.13);
  max-width: 150px;
  overflow-x: auto;
}
</style>